<template>
  <div class="card">
    <div class="card-content">
      <base-loading
        v-if="loading"
        class="superimposed"
      />
      <vue-editor
        v-if="modeEdit"
        :editor-toolbar="customToolbar"
        v-model="content"
        name="content"
        v-validate="'required'"
        data-vv-as="del contenido"
        :use-custom-image-handler="true"
        :editorOptions="editorSettings"
        @image-added="handleImageAdded"
      >
      </vue-editor>
      <div class="block" v-else>
        <p v-if="content" class="text_16 show-ql-styles" v-html="content" />
        <p class="text_20 has-text-weight-bold" v-else>
          Sin información
        </p>
      </div>
      <span v-show="vverrors.has('content')" class="help is-danger">{{ vverrors.first('content') }}</span>
    </div>
  </div>
</template>

<script>

import { VueEditor, Quill } from 'vue2-editor'
import CustomImage from './CustomImage.js'
import ImageResize from 'quill-image-resize-vue'
import { mapActions } from 'vuex'

Quill.register({
  'formats/image': CustomImage
}, true)
Quill.register('modules/imageResize', ImageResize)

export default {
  name: 'CardContentPublication',
  inject: ['$validator'],
  components: {
    VueEditor,
    BaseLoading: () => import('@/components/UI/Loading.vue')
  },
  data () {
    return {
      ASSETS_URL: process.env.VUE_APP_URL_FILES,
      loading: false,
      content: this.publicationContent,
      editorSettings: {
        modules: {
          imageResize: {}
        }
      },
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
        ['blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ['link', 'image'],
        ['clean'] // remove formatting button
      ]
    }
  },
  props: {
    publicationContent: {
      type: String,
      default: null
    },
    modeEdit: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    ...mapActions(['uploadFile']),
    async handleImageAdded (file, Editor, cursorLocation, resetUploader) {
      this.loading = true
      const { success, image } = await this.uploadFile(file)
      this.loading = false

      if (success) {
        Editor.insertEmbed(cursorLocation, 'image', `${this.ASSETS_URL}${image}`)
        resetUploader()
      }
    },
    getLocalContent () {
      console.log(this.content)
      this.$emit('set-value', { name: 'description', value: this.content })
    }
  }
}
</script>

<style lang="scss">
  .card {
    border-radius: 18px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.11);
    background-color: #ffffff;
    min-height: 30vh;
  }
  .card-content {
    position: relative;
  }
  .superimposed.spinner {
    position: absolute;
    margin: auto;
    top: 0; left: 0; bottom: 0; right: 0;
  }
  .show-ql-styles ul {
    list-style: circle !important;
  }
  .show-ql-styles .ql-indent-1 {
    margin-left: 1.5em;
  }
  .show-ql-styles .ql-indent-2 {
    margin-left: 3em;
  }
  .show-ql-styles .ql-indent-3 {
    margin-left: 4.5em;
  }
  .show-ql-styles .ql-indent-4 {
    margin-left: 6em;
  }
  .show-ql-styles .ql-indent-5 {
    margin-left: 7.5em;
  }
  .show-ql-styles .ql-indent-6 {
    margin-left: 9em;
  }
  .show-ql-styles .ql-indent-7 {
    margin-left: 10.5em;
  }

  .show-ql-styles blockquote {
    border-left: 4px solid #d0eac4;
    padding-left: 1em;
    margin-left: 0;
    margin-right: 0;
    font-size: 16px;
    line-height: 1.5;
    color: #262626;
  }

  .show-ql-styles .ql-align-center {
    text-align: center;
  }
  .show-ql-styles .ql-align-right {
    text-align:right;
  }
  .show-ql-styles .ql-align-left {
    text-align: left;
  }
  .show-ql-styles .ql-align-justify {
    text-align: justify;
  }

  .show-ql-styles h1, .show-ql-styles h2, .show-ql-styles h3, .show-ql-styles h4, .show-ql-styles h5, .show-ql-styles h6 {
    line-height: 1.19;
    color: #262626;
  }

  .show-ql-styles h1 {
    font-size: 32px;
  }
  .show-ql-styles h2 {
    font-size: 28px;
  }
  .show-ql-styles h3 {
    font-size: 24px;
  }
  .show-ql-styles h4 {
    font-size: 20px;
  }
  .show-ql-styles h5 {
    font-size: 16px;
  }
  .show-ql-styles h6 {
    font-size: 14px;
  }
  .show-ql-styles ol .ql-align-center {
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
  }
  .show-ql-styles ol .ql-align-left  {
    margin-left: 0;
    margin-right: auto;
    width: fit-content;
  }
  .show-ql-styles ol .ql-align-right  {
    margin-left: auto;
    margin-right: 0;
    width: fit-content;
  }
  .show-ql-styles ol .ql-align-justify  {
    margin-left: 0;
    margin-right: 0;
    width: fit-content;
  }
</style>
