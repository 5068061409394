//
import Quill from 'quill'

console.log('TESTING')

const Image = Quill.import('formats/image')

const ATTRIBUTES = [
  'alt',
  'height',
  'width',
  'style'
]
export default class CustomImage extends Image {
  static formats (domNode) {
    return ATTRIBUTES.reduce(function (formats, attribute) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute)
      }
      console.log('TESTING')
      return formats
    }, {})
  }

  format (name, value) {
    if (ATTRIBUTES.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value)
      } else {
        this.domNode.removeAttribute(name)
      }
    } else {
      super.format(name, value)
    }
  }
}
